import React from 'react'
import styled from 'styled-components'
import SmallTitle from '../SmallTitle'
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../../utils/linkResolver';
import HoverEffect from '../HoverEffect'

const ContractWrapper = styled.div`
  background:${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.lightest};
  padding:3em 1.5em;
  @media (min-width: 562px) {
    padding:6em 3em;
  }
  @media (min-width: 1024px) {
    min-height:100vh;
    display:flex;
    flex-flow:column;
    justify-content:center;
    padding:9em 3em;
  }
  .col{
    &:first-child{
      margin-bottom:2em;
    }
    @media (min-width: 562px) {
      width:${(883 / 1343)*100}%;
      &:first-child{
        margin-left:calc(${(76 / 1343)*100}% + 3em);
        margin-bottom:6em;
      }
      &:last-child{
        margin-left:calc(${(421 / 1343)*100}% + 3em);
      }
    }
  }
  p{
    line-height:1.125;
    font-size:${props => props.theme.fontSizes.mobile.l};
    font-style:italic;
    text-transform:uppercase;
    @media (min-width: 562px) {
      font-size:${props => props.theme.fontSizes.tablet.l};
    }
    @media (min-width: 1024px) {
      font-size:${props => props.theme.fontSizes.desktop.l};
    }
    a{
      display:block;
    }
  }
`

const Contact = (props) => {
  const{
    email,
    phone,
    address
  } = props
  return(
    <ContractWrapper>
      <div className="col">
        <SmallTitle color="darkest" className="animatable">
          <h3>Get in Touch</h3>
        </SmallTitle>
        <p>
          {email &&
            <a href={email} className="animatable">
              <HoverEffect>
                {email.split('mailto:')[1]}
              </HoverEffect>
            </a>
          }
          {phone &&
            <a href={phone} className="animatable">
              <HoverEffect>
                {phone.split('tel:')[1]}
              </HoverEffect>
            </a>
          }
        </p>
      </div>
      <div className="col">
        <SmallTitle color="darkest" className="animatable">
          <h3>Address</h3>
        </SmallTitle>
        <HoverEffect className="animatable" >
          {address && RichText.render(address, linkResolver)}
        </HoverEffect>
      </div>
    </ContractWrapper>
  )
}

export default Contact