import React from "react"
import {graphql} from 'gatsby'
import styled from 'styled-components'
import Layout from "../components/layout"
import Hero from '../components/Hero'
import Slices from '../components/Slices'
import Description from '../components/Description'
import Contact from '../components/Contact'
import get from 'lodash/get'
import InViewBatch from '../components/InViewBatch'
import SEO from "../components/seo"
const Intro = styled.article`
  margin-bottom:3em;
  @media (min-width: 562px) {
    margin-left:calc(${(76 / 1343)*100}% + 6em);
  }
  .description-wrapper{
    padding:0 1.5em;
    @media (min-width: 562px) {
      padding:0;
    }
  }
`

const About = (props) => {
  const edges = get(props.data, 'prismic.allAbouts.edges', [])
  const data = get(edges, '0.node')

  if (!data) {
    return null
  }

  const title_line_1 = get(data,'title_line_1[0].text'),
        title_line_2 = get(data,'title_line_2[0].text'),
        thumbnail_file = get(data,'thumbnail_file'),
        description = get(data, 'description'),
        content = get(data, 'content'),
        email = get(data,'email.url'),
        phone = get(data,'phone.url'),
        address = get(data,'address')
  return(
    <Layout>
      <SEO
        title="About"
        description={get(description,'[0].text')}
        image={get(thumbnail_file,'url')  + '&w=1280&h=630'}
      />
      <Hero
        img={thumbnail_file}
      />
      <InViewBatch>
        <Intro>
          <Description richText={description} />
        </Intro>
        <Slices data={content} />
        <Contact
          email={email}
          phone={phone}
          address={address}
        />
      </InViewBatch>
    </Layout>
  )
}
export const query = graphql`
  query About {
    prismic {
      allAbouts {
        edges {
          node {
            address
            email {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            phone {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            content {
              ... on PRISMIC_AboutContentList {
                type
                label
                primary {
                  title
                }
                fields {
                  link {
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                  name
                }
              }
              ... on PRISMIC_AboutContentText {
                type
                label
                primary {
                  text
                }
              }
              ... on PRISMIC_AboutContentImage_row {
                type
                label
                fields {
                  image_file
                }
              }
              ... on PRISMIC_AboutContentVideo {
                type
                label
                primary {
                  thumbnail_file
                  video_file {
                    ... on PRISMIC__FileLink {
                      url
                    }
                  }
                  video_title
                }
              }
            }
            description
            thumbnail_file
          }
        }
      }
    }
  }
`

export default About

About.query = query